import React from 'react'
import { Tag } from 'antd'
import CoreRenderFunction from 'DisplayCore'
import { applyColor } from 'utils/apply-color'
import { handleNestedObjectKey } from 'utils'

const TechBaseTag = props => {
  let {
    color,
    style,
    children,
    dependency
  } = props.item

  if(dependency && !handleNestedObjectKey( props, dependency )){
    return <></>
  }
  return(
    <Tag
      color={ applyColor( color, props.colors )}
      style={ style }
    >
      {
        <CoreRenderFunction
          { ...props }
          item={ children }
        />
      }
    </Tag>
  )
}

export default TechBaseTag
