import React from 'react'
import { Viewer, Worker } from '@react-pdf-viewer/core'
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout'
import { toolbarPlugin } from '@react-pdf-viewer/toolbar'

import { handleNestedObjectKey } from 'utils'

import '@react-pdf-viewer/core/lib/styles/index.css'
import '@react-pdf-viewer/default-layout/lib/styles/index.css'

const PDFViewer = props => {
  let {
    dataKey
  } = props.item
  
  const toolbarPluginInstance = toolbarPlugin()
  const { Toolbar } = toolbarPluginInstance

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    renderToolbar: () => (
      <Toolbar>
        { ToolbarSlot => {
          const {
            GoToPreviousPage,
            CurrentPageInput,
            NumberOfPages,
            GoToNextPage,
            ZoomOut,
            Zoom,
            ZoomIn,
            SwitchTheme,
            EnterFullScreen,
            Download
          } = ToolbarSlot
          return (
            <>
              <GoToPreviousPage />
              <CurrentPageInput />
              <NumberOfPages />
              <GoToNextPage />
              <div className='ml-auto px-2'><ZoomOut /></div>
              <Zoom />
              <ZoomIn />
              <div className='ml-auto px-2'><SwitchTheme /></div>
              <Download/>
              <EnterFullScreen />
            </>
          )
        }}
      </Toolbar>
    ),
    sidebarTabs: defaultTabs => [ defaultTabs[ 0 ] ]
  })

  return (
    <div
      style={{
        maxWidth: '1340px',
        height: '80vh',
        margin: 'auto',
        justifyContent: 'center',
        alignItems: 'center',
        background: '#d1d1d1',
        display: 'flex'
      }}>
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
          <Viewer
            fileUrl={ handleNestedObjectKey( props, dataKey ) }
            plugins={ [ defaultLayoutPluginInstance, toolbarPluginInstance ] } />
        </Worker>
      {/* <iframe width='100%' height='100%' src={ `https://docs.google.com/gview?url=${ handleNestedObjectKey( props, dataKey ) }&embedded=true` } /> */}
    </div>
  )
}

export default PDFViewer
