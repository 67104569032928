import React, { Component } from "react"
import { connect } from "react-redux"
import MetisMenu from "react-metismenu"
import _ from "lodash"
import i18next from 'i18next'

import { setEnableMobileMenu } from "reducers/ThemeOptions"
import { applyColor } from 'utils/apply-color'
import { MainNav } from "./dataMainNav"

class VerticalNavWrapper extends Component {
  state = {
    readMenu: [],
  }

  componentDidMount = () => {
    if ( this.props.data.ProfileReducer.permissions ) {
      this.setState({
        readMenu: this.readPermissionHandler( MainNav, this.props.siteModules ),
      })
    }
  }

  componentDidUpdate = (pp) => {
    if ( pp.data.ProfileReducer.permissions !== this.props.data.ProfileReducer.permissions || pp.siteModules !== this.props.siteModules ) {
      this.setState({
        readMenu: this.readPermissionHandler( MainNav, this.props.siteModules ),
      })
    }
  }

  getSiteModuleId = ( modules, moduleName ) => {
    return _.find( modules, { name: moduleName } )
  }

  readPermissionHandler = ( navItems, modules ) => {
    const { permissions } = this.props.data.ProfileReducer
    let tmpMenu = []
    let tmpActiveMenu = _.filter( navItems, navItem => {
      if( navItem.module_name ){
        let tmp = _.find( permissions, permission => permission.site_module_id === this.getSiteModuleId( modules, navItem.module_name )?.id )
        if( 
          navItem.module_name === 'Upgrade Mall' && 
          !(
            tmp?.custom_permissions.show_at_side_menu &&
            ( 
              this.props.clientInfo.name === 'Winna' || 
              this.props.profile.client_user_member?.client_tier_id !== this.props.clientInfo.tiers?.[ this.props.clientInfo.tiers.length - 1].id 
            )
          )
        ){
          return false
        }
        return tmp?.can_read
      }
      return true
    })
    tmpActiveMenu.map( item => {
      if( _.isUndefined( item.content ) ){
        tmpMenu.push({
          ...item,
          label: i18next.t( item.label )
        })
      } else {
        let tmpContent = this.readPermissionHandler( item.content, this.getSiteModuleId( modules, item.module_name ).submodules )
        if( tmpContent.length > 0 ){
          tmpMenu.push({
            ...item,
            label: i18next.t( item.label ),
            content: tmpContent
          })
        }
      }
    })
    return tmpMenu
  }
  
  render = () => {
    return (
      <>
        <style>
          {`
            .vertical-nav-menu .metismenu-container .metismenu-link.active{ background: ${ applyColor( '$primary', this.props.colors ) } }
            .vertical-nav-menu .metismenu-container .metismenu-link:hover{ background: ${ applyColor( '$primary', this.props.colors ) } }
          `}
        </style>
        <br />
        <MetisMenu 
          content={ this.state.readMenu }
          onSelected={() => this.props.setEnableMobileMenu(!this.props.enableMobileMenu)} 
          activeLinkFromLocation
          className="vertical-nav-menu" 
          iconNamePrefix="" 
          classNameStateIcon="pe-7s-angle-down" />
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  data: state,
  enableMobileMenu: state.ThemeOptions.enableMobileMenu,
  colors: state.ClientReducer.clientInfo.styles?.colors,
  siteModules: state.ClientReducer.siteModules,
  clientInfo: state.ClientReducer.clientInfo,
  profile: state.ProfileReducer.profile
})

export default connect(mapStateToProps, {
  setEnableMobileMenu,
})(VerticalNavWrapper)
