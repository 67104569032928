export const WINNAKYC = {
  content: {
    type: 'modal',
    width: 1200,
    bodyStyle: {
      paddingTop: '20px'
    },
    openActionKey: 'showKYC',
    closeActionKey: 'CLOSE_KYC',
    title: '',
    children: [
      {
        type: 'div',
        className: '',
        style: {
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          marginRight: '-10px'
        },
        children: [
          {
            type: 'text',
            className: 'mr-2',
            content: "{{LANGUAGE}}"
          },
          {
            type: 'language-switcher',
          }
        ]
      },
      {
        type: 'form',
        formRef: 'kycForm',
        onInitial: 'onInitialtKYCForm',
        onFinish: 'onSubmitKYCForm',
        onChange: 'ON_CHANGE_KYC_FORM',
        children: [
          {
            type: 'card',
            title: {
              type: 'text',
              content: "{{PERSONAL_INFORMATION}}"
            },
            headStyle: { background: '$primary', color: '!primary' },
            children: [
              {
                type: 'div',
                className: 'row align-items-end',
                isHide: 'hideIdentityNumber',
                children: [
                  {
                    type: 'div',
                    className: 'col-4',
                    children: [
                      {
                        type: 'select',
                        label: "{{IDENTITY_NUMBER}}",
                        name: 'document_type',
                        inputConfig: {
                          placeholder: "{{IDENTITY_NUMBER}}",
                          size: 'large',
                          dataKey: 'documentOption',
                          label: 'label',
                          value: 'value',
                          defaultValue: 'ic'
                        },
                        roleConfig: true
                      }
                    ]
                  },
                  {
                    type: 'div',
                    className: 'col-8',
                    children: [
                      {
                        type: 'input',
                        rules: [{ required: true, message: `{{PLEASE_ENTER}} {{IDENTITY_NUMBER}}` }, { pattern: /^[a-zA-Z0-9]*$/, message: "{{ONLY_ALPHANUMERIC_VALUE_IS_ACCEPTABLE}}" }],
                        name: 'ic_number',
                        inputConfig: {
                          size: 'large'
                        },
                        roleConfig: true
                      }
                    ]
                  }
                ]
              },
              {
                type: 'div',
                className: 'row',
                children: [
                  {
                    type: 'div',
                    className: 'col-6',
                    children: [
                      {
                        type: 'text',
                        content: '<span style="color: #ff0000;">* </span> {{FRONT_IC_OR_PASSPORT}}'
                      },
                      {
                        type: 'file-pond',
                        files: 'uploadedICFront',
                        acceptedFileTypes: [ 'image/*' ],
                        allowMultiple: false,
                        returnFileInfo: true,
                        actionKey: 'UPLOAD_IC_FRONT'
                      },
                      {
                        type: 'text',
                        isHide: 'hideFormFeedbackICFront',
                        className: 'text-danger',
                        content: "{{PLEASE_UPLOAD_IC_FRONT}}"
                      }
                    ]
                  },
                  {
                    type: 'div',
                    className: 'col-6',
                    isHide: 'hideBackIC',
                    children: [
                      {
                        type: 'text',
                        content: "{{BACK_IC}}"
                      },
                      {
                        type: 'file-pond',
                        files: 'uploadedICBack',
                        acceptedFileTypes: [ 'image/*' ],
                        allowMultiple: false,
                        returnFileInfo: true,
                        actionKey: 'UPLOAD_IC_BACK'
                      }
                    ]
                  }
                ]
              },
              {
                type: 'div',
                className: 'row',
                isHide: 'hideSSM',
                children: [
                  {
                    type: 'div',
                    className: 'col-6',
                    children: [
                      {
                        type: 'text',
                        content: '<span style="color: #ff0000;">* </span> {{SSM_REGISTRATION_CERT}}'
                      },
                      {
                        type: 'file-pond',
                        files: 'uploadedSSMCert',
                        acceptedFileTypes: [ 'image/*' ],
                        allowMultiple: false,
                        returnFileInfo: true,
                        actionKey: 'UPLOAD_SSM_CERT'
                      },
                      {
                        type: 'text',
                        isHide: 'hideFormFeedbackSSMCert',
                        className: 'text-danger',
                        content: "{{PLEASE_UPLOAD_SSM_CERT}}"
                      }
                    ]
                  },
                  {
                    type: 'div',
                    className: 'col-6',
                    children: [
                      {
                        type: 'text',
                        content: "{{SSM_REGISTRATION_CERT_WITH_IC}}"
                      },
                      {
                        type: 'file-pond',
                        files: 'uploadedSSMCertWithIC',
                        acceptedFileTypes: [ 'image/*' ],
                        allowMultiple: false,
                        returnFileInfo: true,
                        actionKey: 'UPLOAD_SSM_CERT_WITH_IC'
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            type: 'card',
            isHide: 'hideBankDetail',
            title: {
              type: 'text',
              content: "{{BANK_DETAIL}}"
            },
            headStyle: { background: '$primary', color: '!primary' },
            children: [
              {
                type: 'div',
                className: 'row',
                children: [
                  {
                    type: 'div',
                    className: 'col-md-6',
                    children: [
                      {
                        type: 'select',
                        label: "{{BANK_NAME}}",
                        name: 'bank_id',
                        rules: [{ required: true, message: "{{SELECT_YOUR_BANK}}" }],
                        actionKey: 'SELECT_YOUR_BANK',
                        inputConfig: {
                          placeholder: "{{SELECT_YOUR_BANK}}",
                          size: 'large',
                          dataKey: 'banks',
                          label: 'label',
                          value: 'id'
                        }
                      }
                    ]
                  },
                  {
                    type: 'div',
                    className: 'col-md-6',
                    children: [
                      {
                        type: 'input',
                        label: "{{BANK_ACCOUNT_NO}}",
                        rules: [{ required: true, message: `{{PLEASE_ENTER}} {{BANK_ACCOUNT_NO}}` },{ pattern: /^[0-9]*$/, message: "{{ONLY_NUMBERS}}" }],
                        name: 'account_number',
                        inputConfig: {
                          placeholder: "{{BANK_ACCOUNT_NO}}",
                          min: 0,
                          style:{ width: '100%' }
                        }
                      }
                    ]
                  }
                ]
              },
              {
                type: 'div',
                className: 'row',
                children: [
                  {
                    type: 'div',
                    className: 'col-md-6',
                    children: [
                      {
                        type: 'input',
                        label: "{{HOLDER_NAME}}",
                        name: 'holder_name',
                        inputConfig: {
                          placeholder: "{{HOLDER_NAME}}",
                          size: 'large'
                        }
                      }
                    ]
                  },
                  {
                    type: 'div',
                    className: 'col-md-6',
                    children: [
                      {
                        type: 'input',
                        label: "{{INCOME_TAX_NO}}",
                        name: 'income_tax_number',
                        inputConfig: {
                          placeholder: "{{INCOME_TAX_NO}}",
                          size: 'large'
                        }
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            type: 'card',
            isHide: 'hideSecurityPin',
            title: {
              type: 'text',
              content: "{{SECURITY_PIN}}"
            },
            headStyle: { background: '$primary', color: '!primary' },
            children: [
              {
                type: 'div',
                className: 'row',
                children: [
                  {
                    type: 'div',
                    className: 'col-6',
                    children: [
                      {
                        type: 'input',
                        variant: 'password',
                        rules: [
                          { required: true, message: `{{PLEASE_ENTER}} {{SECURITY_PIN}}` },
                          { pattern: /^[\d]{6,6}$/, message: "{{SECURITY_PIN_ERROR}}" }
                        ],
                        label: "{{SECURITY_PIN}}",
                        name: 'security_pin',
                        inputConfig: {
                          placeholder: "{{SECURITY_PIN}}",
                          size: 'large'
                        }
                      }
                    ]
                  },
                  {
                    type: 'div',
                    className: 'col-6',
                    children: [
                      {
                        type: 'input',
                        variant: 'password',
                        rules: [
                          { required: true, message: `{{PLEASE_ENTER}} {{CONFIRM_SECURITY_PIN}}` },
                          { equal: 'security_pin', message: "{{SECURITY_PIN_CONFIRMATION_ERROR}}" }
                        ],
                        label: "{{CONFIRM_SECURITY_PIN}}",
                        name: 'security_pin_confirmation',
                        inputConfig: {
                          placeholder: "{{CONFIRM_SECURITY_PIN}}",
                          size: 'large'
                        }
                      }
                    ]
                  }
                ]
              }
            ]
          },
          { //section: submit button
            type: 'div',
            className: 'd-flex justify-content-end w-100',
            children: [
              {
                type: 'button',
                htmlType: 'submit',
                buttonType: 'text',
                className: 'custom-color my-3 ml-auto',
                style: {
                  backgroundColor: '$quaternary',
                  color: '#ffffff'
                },
                children: [
                  {
                    type: 'text',
                    content: "{{SUBMIT}}"
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
}
