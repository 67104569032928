import React, { Suspense, lazy } from "react"
import LoadingOverlay from "components/Indicator/LoadingOverlay"
import { connect } from "react-redux"

const MainRegistration = lazy(() => import("./main"))
const WinnaRegistration = lazy(() => import("./Winna"))

const Registration = (props) => {
  return (
    <div className={`${props.clientInfo.name}`}>
      {props.clientInfo.name === "Winna" ? (
        <Suspense fallback={<LoadingOverlay />}>
          <WinnaRegistration />
        </Suspense>
      ) : (
        <Suspense fallback={<LoadingOverlay />}>
          <MainRegistration />
        </Suspense>
      )}
    </div>
  )
}

const mapStateToProps = (state) => ({
  clientInfo: state.ClientReducer.clientInfo,
})

export default connect(mapStateToProps)(Registration)
